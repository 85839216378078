import OrderIcon from '@mui/icons-material/AttachMoney';
import OrderList from './list';
import OrderEdit from './edit';
import OrderShow from './single'


export default {
    list: OrderList,
    edit: OrderEdit,
    icon: OrderIcon,
    show: OrderShow
};
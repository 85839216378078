import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Text from '../../../../src/components/Text';
import Label from '../../../../src/components/Label';
import { Show, SimpleShowLayout, TextField, EmailField, ReferenceField, useRecordContext } from "react-admin";
import { Badge } from '@mui/icons-material';



const StatusLabel = () => {
  const { t }: { t: any } = useTranslation();
let user  = useRecordContext()
let map ={
  "active":{
    state: "success",
    text: t("Active")
  },
  "inactive": {
    state: "error",
    text: t("Inactive")
  }
}
const { text, state }: any = map[user.status];

  return (
    <Label color={state}>
                    <DoneTwoToneIcon fontSize="small" />
                    <b>{text}</b>
                  </Label>
  )
}
const PermissionLabel = () => {
  const { t }: { t: any } = useTranslation();
  let user  = useRecordContext()
  const map = {

    4: {
      text: t('Project manager'),
      color: 'info'
    },
    16: {
      text: t('Account manager'),
      color: 'info'
    },
    2048: {
      text: t('Super admin'),
      color: 'info'
    }
  };
  const { text, color }: any = map[user.permissionLevel];

  return (
    <Label color={color}>
      <b>{text}</b>
    </Label>
  )
}
function InfoUserTab() {
  const { t }: { t: any } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
                <Box pb={2}>
          <Typography variant="h3">{t('User informations')}</Typography>
          <Typography variant="subtitle2">
            {t('All information about this user')}
          </Typography>
        </Box>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('Personal Details')}
              </Typography>
              <Typography variant="subtitle2">
                {t('Manage informations related to your personal details')}
              </Typography>
            </Box>
            <Button variant="text" startIcon={<EditTwoToneIcon />}>
              {t('Edit')}
            </Button>
          </Box>
          <Divider />
          <CardContent
            sx={{
              p: 4
            }}
          >
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Id')}:
                  </Box>
                </Grid>
                 <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <TextField source="id" />
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Name')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <TextField source="name" sx={{
                      fontWeight: 800,
                      fontSize: 18
                    }}/>
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Account')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                  <ReferenceField source="accountId" reference="accounts" >
                    <TextField source='name' sx={{
                      fontWeight: 800,
                      fontSize: 16
                    }}/>
                  </ReferenceField>
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Email address')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Box
                    sx={{
                      maxWidth: { xs: 'auto', sm: 300 }
                    }}
                  >
                    <EmailField source="email" />
                  </Box>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('Permission Settings')}
              </Typography>
              <Typography variant="subtitle2">
                {t('Manage details related to permissions level')}
              </Typography>
            </Box>
            <Button variant="text" startIcon={<EditTwoToneIcon />}>
              {t('Edit')}
            </Button>
          </Box>
          <Divider />
          <CardContent
            sx={{
              p: 4
            }}
          >
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Permission level')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <PermissionLabel/>
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('User status')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <StatusLabel/>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default InfoUserTab;

import * as Yup from 'yup';
import type { FC } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress
} from '@mui/material';
import useRefMounted from '../../../hooks/useRefMounted';
import { useTranslation } from 'react-i18next';

const LoginJWT: FC = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const login = useLogin();
  const notify = useNotify();
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('The email provided should be a valid email address'))
          .max(255)
          .required(t('The email field is required')),
        password: Yup.string()
          .max(255)
          .required(t('The password field is required'))
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        login({ username: values.email, password: values.password }).catch(() => {
          const errorMessage = 'Invalid email or password'
          notify(errorMessage)
          setStatus({ success: false });
          setErrors({ submit: errorMessage });
        }  
        ).then(()=>{
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            margin="normal"
            autoFocus
            label={t('Email address')}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            margin="normal"
            label={t('Password')}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Box
            alignItems="center"
            display={{ xs: 'block', md: 'flex' }}
            justifyContent="space-between"
          >

            <Link component={RouterLink} to="/account/recover-password" >
              <b>{t('Lost password?')}</b>
            </Link>
          </Box>



          <Button
            sx={{
              mt: 3
            }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {t('Sign in')}
          </Button>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;


import { useState, useCallback, ChangeEvent, useEffect } from 'react';

//import { Helmet } from 'react-helmet-async';
import Footer from '../../../../src/components/Footer';
import { Box, Tabs, Tab, Grid, styled } from '@mui/material';
import { useParams } from 'react-router-dom';
import useRefMounted from '../../../../src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import type { User } from '../../../../src/models/user';
import ProfileCover from './ProfileCover';
import RecentActivity from './RecentActivity';
import Feed from './Feed';
import PopularTags from './PopularTags';
import MyCards from './MyCards';
import Addresses from './Addresses';
import ActivityTab from './ActivityTab';
import InfoUserTab from './InfoUserTab';
import NotificationsTab from './NotificationsTab';
import SecurityTab from './SecurityTab';
import axios from '../../../../src/utils/axios';
import { Show,ShowBase, SimpleShowLayout, TextField, EmailField, ReferenceField, useRecordContext } from "react-admin";
import ComponentShow from '../../../components/PageComponents/ComponentShow';
/*
const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;

      .MuiTabs-indicator {
        box-shadow: none;
      }
    }
`
);
*/

const TabsWrapper = styled(Tabs)(
  () => `
`
);
const Content = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (<ComponentShow title={record.name} subtitle="">
    <ContentShow />
  </ComponentShow>)
}
const UserShow = () => {
  return (
    <>
    <ShowBase >
      <Content/>
    </ShowBase>
    </>

  )
}
const ContentShow = () => {

  const [currentTab, setCurrentTab] = useState<string>('infoUser');
  const { t }: { t: any } = useTranslation();

  const tabs = [
    { value: 'infoUser', label: t('Information') },
    { value: 'security', label: t('Passwords/Security') }
  ];

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <Box
        sx={{
          mt: 3,
          marginBottom:4
        }}
      >
        <Grid
          sx={{
            px: 4,
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >

          <Grid item xs={12} md={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12} md={8}>
            {currentTab === 'infoUser' && <InfoUserTab />}
            {currentTab === 'security' && <SecurityTab />}
          </Grid>
          <Grid item xs={12} md={4}>
            <RecentActivity />
          </Grid>
        </Grid>
      </Box>
      </>
  );
}

export default UserShow
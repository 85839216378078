import { AnyAaaaRecord } from "dns";
import { userInfo } from "os";
import BASE_API_URL from "../configUrl";

const authProvider = {
    login: ({ username, password }: any) => {
        let formLogin =
        {
            "email": username,
            "password": password
        }

        const request = new Request(`${BASE_API_URL}/auth/login`, {
            method: 'POST',
            body: JSON.stringify(formLogin),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('access_token', JSON.stringify(auth));
                console.log(localStorage.getItem('access_token'))
                
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    checkAuth: () => localStorage.getItem('access_token')
        ? Promise.resolve()
        // : Promise.reject({ redirectTo: '/no-access' }),
        : Promise.reject({ redirectTo: '/login' }),

    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },
    getIdentity: () => {

        const { access_token } = JSON.parse(
            localStorage.getItem("access_token") || "{}"
        );
        const request = new Request(`${BASE_API_URL}/users/personnal`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${access_token}` }),
        });
        return fetch(request).then(response =>{
            let responseJson = response.json()
            return Promise.resolve(responseJson);
        }).catch(() => {
                throw new Error('Network error')
        });
    },

    checkError: (error: any) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem('access_token');
        return Promise.resolve();
    },
    // ...
};

export interface UserIdentity {
    id: Identifier;
    fullName?: string;
    avatar?: string;
    [key: string]: any;
}
export type Identifier = string | number;

export default authProvider;
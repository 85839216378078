import jsonServerProvider from "ra-data-json-server";
import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils, withLifecycleCallbacks } from "react-admin";
import { combineDataProviders } from 'react-admin';
import BASE_API_URL from "../configUrl";


const httpClient = (url: any, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const { access_token } = JSON.parse(
    localStorage.getItem("access_token") || "{}"
  );

  options.headers.set("Authorization", `Bearer ${access_token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider1 = withLifecycleCallbacks(
  simpleRestProvider(
    BASE_API_URL,
    httpClient
  ),
  [
    {
      resource: "orders",
      afterRead: async (record, dataProvider) => {
        record.id = record.OrderId;
        return record;
      }
    },
    {
      resource: "projects",
      afterRead: async (record, dataProvider) => {
        record.status = (record.status === "active") ? true : false;
        //record.id = record.Id;
        return record;
      },
      beforeGetOne: async(params, dataProvider) => {
        console.table(params)
        return params
      },
    },
  ]
);
const dataProvider2 = jsonServerProvider(
  BASE_API_URL,
  httpClient
);

const cacheDataProviderProxy = (dataProvider: any, duration =  5 * 60 * 1000) =>
    new Proxy(dataProvider, {
        get: (target, name: string) => (resource: any, params: any) => {
            if (name === 'getMany' || name === 'getList') {
                return dataProvider[name](resource, params).then((response: any) => {
                    const validUntil = new Date();
                    validUntil.setTime(validUntil.getTime() + duration);
                    response.validUntil = validUntil;
                    return response;
                });
            }
            return dataProvider[name](resource, params);
        },
    });

const dataProvider = combineDataProviders((resource) => {
  switch (resource) {
      case 'orders':
      case 'projects':
      case 'accounts':  
      case 'users':
          return cacheDataProviderProxy(dataProvider1);
          //return dataProvider1;
          case 'users':
          return dataProvider2;
      default:
          throw new Error(`Unknown resource: ${resource}`);
  }
});

export default dataProvider;

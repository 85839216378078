import * as React from 'react';
import {
    BooleanInput,
    DateField,
    Edit,
    Form,
    Labeled,
    ReferenceField,
    SelectInput,
    TextField,
    Toolbar,
    useRecordContext,
    useTranslate,
} from 'react-admin';
import { Card, CardContent, Box, Grid, Typography} from '@mui/material';
import { Project} from '../../models/project';

const OrderEdit = () => (
    <Edit title={<ProjectTitle />} component="div">
        <ProjectForm />
    </Edit>
);

const ProjectTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext<Project>();
    return record ? (
        <span>
            {translate('resources.project.title', {
                name: record.name,
            })}
        </span>
    ) : null;
};


const Spacer = () => <Box m={1}>&nbsp;</Box>;

const ProjectForm = () => {
    const translate = useTranslate();
    return (
        <Form>
            <Box maxWidth="50em">
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={8}>
                                <Typography variant="h6" gutterBottom>
                                    Project
                                </Typography>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Labeled source="date">
                                            <DateField source="date" />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Labeled source="name">
                                            <TextField source="name" />
                                        </Labeled>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <SelectInput
                                            source="status"
                                            choices={[
                                                {
                                                    id: 'delivered',
                                                    name: 'delivered',
                                                },
                                                {
                                                    id: 'ordered',
                                                    name: 'ordered',
                                                },
                                                {
                                                    id: 'cancelled',
                                                    name: 'cancelled',
                                                },
                                                {
                                                    id: 'unknown',
                                                    name: 'unknown',
                                                    disabled: true,
                                                },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <Box mt={2}>
                                            <BooleanInput
                                                row={true}
                                                source="returned"
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.orders.section.customer'
                                    )}
                                </Typography>
                                <ReferenceField
                                    source="customer_id"
                                    reference="customers"
                                    link={false}
                                >
                                </ReferenceField>
                                <Spacer />

                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.commands.section.shipping_address'
                                    )}
                                </Typography>
                                <ReferenceField
                                    source="customer_id"
                                    reference="customers"
                                    link={false}
                                >
                                </ReferenceField>
                            </Grid>
                        </Grid>
                        <Spacer />

                        <Typography variant="h6" gutterBottom>
                            {translate('resources.commands.section.items')}
                        </Typography>
                        <Spacer />

                        <Typography variant="h6" gutterBottom>
                            {translate('resources.commands.section.total')}
                        </Typography>
                    </CardContent>
                    <Toolbar />
                </Card>
            </Box>
        </Form>
    );
};

export default OrderEdit;
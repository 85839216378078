import { Admin, Resource} from "react-admin";
import orders from '../pages/orders';
import users from '../pages/users';
import accounts from '../pages/accounts';
import projects from '../pages/projects';
import dashboard from '../dashboards/general';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import CoinhouseSolutionsAdminLayout from '../layout/CoinhouseSolutionsAdminLayout'
import { QueryClient } from 'react-query';
import { themeCreator } from "../themes/base";
import CoinhouseSolutionsLoginPage from "../pages/Auth/Login/Basic";
import LoginCover from "../pages/Auth/Login/Cover";
const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
          staleTime: 5 * 60 * 1000, // 5 minutes
      },
  },
});

const App = () => (
  <Admin loginPage={LoginCover} dashboard={dashboard} layout={CoinhouseSolutionsAdminLayout} authProvider={authProvider} dataProvider={dataProvider} theme={themeCreator("coinhouseTheme")} queryClient={queryClient} title="Coinhouse Solutions - Admin">
    <Resource
                name="accounts"
                recordRepresentation="name"
                {...accounts}
                options={{ label: 'Accounts' }}
            />
    <Resource
                name="users"
                {...users}
                options={{ label: 'Users' }}
            />
    <Resource
                name="projects"
                {...projects}
                options={{ label: 'Projects' }}
            />

    <Resource
                name="orders"
                {...orders}
                options={{ label: 'Orders' }}
            />     
  </Admin>
);

export default App;
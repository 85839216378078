import type { ReactNode } from 'react';

import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Orders',
        //icon: SmartToyTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Orders list',
            link: '/dashboard'
          },
          {
            name: 'Kyt',
            link: 'kyt',
          }
        ]
      }
    ]
  },
  {
    heading: 'Management',
    items: [
      {
        name: 'Orders',
        //icon: SmartToyTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Orders list',
            link: '/orders'
          },
          {
            name: 'Payments',
            link: '/payments',
          }
        ]
      }
    ]
  },
  {
    heading: 'Settings',
    items: [
      {
        name: 'Access management',
        //icon: BackupTableTwoToneIcon,
        link: '',
        items: [
          {
            name: 'Users',
            link: '/users',
            //badge: '',
          },
          {
            name: 'Projects',
            link: '/projects',
            //badge: '',
          },
          {
            name: 'Accounts',
            link: '/accounts'
          }
        ]
      }
    ]
  }
];

export default menuItems;


import {
    Edit,
    SimpleForm,
    ReferenceInput,
    TextInput,
    SelectInput
  } from "react-admin";
    
  const UserEdit = () => (
    <Edit>
      <SimpleForm>
        <ReferenceInput source="userId" reference="users" />
        <TextInput source="name" />
        <TextInput source="email" />
        <TextInput source="password" />
        <SelectInput source="status" choices={[
    { id: 'active', name: 'active' },
    { id: 'inactive', name: 'inactive' }
]} />
        <SelectInput source="permissionLevel" choices={[
    { id: '4', name: 'normal' },
    { id: '16', name: 'projects manager' },
    { id: '2048', name: 'super admin' },
]} />
      </SimpleForm>
    </Edit>
  );

  export default UserEdit;
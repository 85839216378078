import {

  ListBase,
  Title,
  ListToolbar
} from 'react-admin';
import { Card, Box, Grid } from '@mui/material';
import PageTitleWrapper from './PageTitleWrapper';
import PageHeader from './PageHeader'
import { Pagination } from './Pagination';


const ComponentList = ({ children, actions, filters, title, subtitle, ...props }) => (
  <Box sx={{
    marginTop: 0,
  }}>

      <PageTitleWrapper>
        <PageHeader title={title} subtitle={subtitle}/>
      </PageTitleWrapper>
    <Grid
      sx={{
        px: 4
      }}
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12}>
        <ListBase {...props}>
          <Title title={title} />
          <Card>
            <Box p={2}>
              <ListToolbar
                filters={filters}
                actions={actions}
              />
            </Box>
            {children}
          </Card>
          <Pagination />
        </ListBase>
      </Grid>
    </Grid>
  </Box>
);



export default ComponentList;
import {

  ListBase,
  Title,
  ListToolbar,
  Pagination,
} from 'react-admin';
import { Card, Box, Grid } from '@mui/material';
import PageTitleWrapper from './PageTitleWrapper';
import PageHeader from './PageHeader'


const ComponentShow = ({ children, title, subtitle}) => (
  <>
    <PageTitleWrapper>
      <PageHeader title={title} subtitle={subtitle} />
    </PageTitleWrapper>
    <Grid
      sx={{
        px: 4,
      }}
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={4}
    >
      <Grid item xs={12} >
        <Card>
          {children}
        </Card>
      </Grid>
    </Grid>
  </>
);



export default ComponentShow;
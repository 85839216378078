import { Show, SimpleShowLayout, TextField, DateField, Labeled, TabbedShowLayout,ReferenceManyField, BooleanField, Datagrid, ShowButton } from 'react-admin';
import { Card, Stack, CardContent, Typography, Grid } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
export const ProjectShow = () => (
    <Show>
        <SimpleShowLayout>
            <TextField label="Id" source="projectId" />
            <TextField label="Name" source="name" />
            <TextField label="Type" source="typeProject" />
            <BooleanField label="Status" source="status" />
            <DateField label="Created" source="created" showTime={true} />
            <DateField label="Last update" source="updated" showTime={true} />

        </SimpleShowLayout>
        <TabbedShowLayout>
            <TabbedShowLayout.Tab label="Parameters" >
                <TextField label="API Key" source="apiKey" />
                <TextField label="Password HMAC" source="hmacPassword" />
                <TextField label="Code Project" source="codeProject" />
                <Card >
                    <CardContent>
                        <Stack spacing={1}>
                            <Typography variant='h5' component='h5'>Configuration URL's</Typography>
                            <Labeled >
                                <TextField source="parameters.urlRedirectSuccess" label="Url success" />
                            </Labeled>
                            <Labeled >
                                <TextField source="parameters.urlRedirectPending" label="Url pending" />
                            </Labeled>
                            <Labeled >
                                <TextField source="parameters.urlRedirectFailed" label="Url failed" />
                            </Labeled>
                            <Labeled >
                                <TextField source="parameters.urlRedirectError" label="Url error" />
                            </Labeled>
                            <Labeled fullWidth={false} >
                                <TextField source="parameters.webhookUrl" label='Webhook' />
                            </Labeled>

                            <Grid container spacing={2} sx={{ margin: 2 }}>
            <Grid item xs={12} sm={6}>
                <TextField label="Title" source="title" />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField source="parameters.urlRedirectSuccess" label="Url success" />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField source="parameters.urlRedirectSuccess" label="Url success" />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField source="parameters.urlRedirectSuccess" label="Url success" />
            </Grid>
        </Grid>
                        </Stack>
                    </CardContent>
                </Card>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="User" >
                <TextField label="Wallet Customer" source="CustomerAddress" />
                <TextField label="Email" source="customer.email" />
                <TextField label="Firstname" source="customer.firstname" />
                <TextField label="Lastname" source="customer.lastname" />
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label="Opérations" >
            <ReferenceManyField label="" reference="orders" target="codeProject" source="codeProject">
                <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField label="OrderId" source="id" />
                <TextField label="Created at" source="dateCreated" />
                <TextField label="Amount" source="amount" />
                <BooleanField label="OrderId" source="success" />   
                <ShowButton />
                </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    </Show>
);

export default ProjectShow;

import { useState, useCallback, ChangeEvent, useEffect } from 'react';

//import { Helmet } from 'react-helmet-async';
import { Box, Tabs, Tab, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { OrderCard } from '../../../../src/models/orderCard';
import RecentActivity from './RecentActivity';

import InfoOrderTab from './InfoOrderTab';
import InfoPaymentTab from './InfoPaymentTab';

import { ShowBase, useRecordContext, useGetManyReference } from "react-admin";
import ComponentShow from '../../../components/PageComponents/ComponentShow';
/*
const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;

      .MuiTabs-indicator {
        box-shadow: none;
      }
    }
`
);
*/

const TabsWrapper = styled(Tabs)(
  () => `
`
);
const Content = () => {
  const record = useRecordContext();
  if (!record) return null;
  return (<ComponentShow title={record.OrderId} subtitle="">
    <ContentShow />
  </ComponentShow>)
}
const OrderShow = () => {
  return (
    <>
    <ShowBase >
      <Content/>
    </ShowBase>
    </>

  )
}
const ContentShow = () => {

  const [currentTab, setCurrentTab] = useState<string>('infoOrder');
  const { t }: { t: any } = useTranslation();
  const record = useRecordContext();
  const { data, isLoading, error } = useGetManyReference(
    'orders',
    {
      target: 'paymentByOrder',
      id: record.id
    }
  );

  const tabs = [
    { value: 'infoOrder', label: t('Information') }
    
  ];
  if(data?.length >0) {
    tabs.push({ value: 'InfoPaymentTab', label: t('Payments') })
  }
  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);
  };

  return (
    <>
      <Box
        sx={{
          mt: 3,
          marginBottom:4
        }}
      >
        <Grid
          sx={{
            px: 4,
          }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={4}
        >

          <Grid item xs={12} md={12}>
            <TabsWrapper
              onChange={handleTabsChange}
              value={currentTab}
              variant="scrollable"
              scrollButtons="auto"
              textColor="primary"
              indicatorColor="primary"
            >
              {tabs.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </TabsWrapper>
          </Grid>
          <Grid item xs={12} md={8}>
            {currentTab === 'infoOrder' && <InfoOrderTab />}
            {currentTab === 'InfoPaymentTab' && <InfoPaymentTab payments={data}/>}
          </Grid>
          <Grid item xs={12} md={4}>
            <RecentActivity />
          </Grid>
        </Grid>
      </Box>
      </>
  );
}

export default OrderShow
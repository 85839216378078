
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  PasswordInput,
  BooleanInput
} from "react-admin";
import {
  Typography,
  CardContent,
  Card,
  Box,
  Divider
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ComponentEdit from "../../components/PageComponents/ComponentEdit";

const UserCreate = () => {

  const { t }: { t: any } = useTranslation();
  return (

    <ComponentEdit title="New User" subtitle="To create a new user for a specific account" >
      <Card>
        <Box
          p={3}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h4" gutterBottom>
              {t('User Details')}
            </Typography>
            <Typography variant="subtitle2">
              {t('Please fill all inputs to create a new user')}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <CardContent
          sx={{
            p: 4
          }}
        >
          <Create>
            <SimpleForm>
              <ReferenceInput source="accountId" reference="accounts" fullWidth sx={{padding:'16.5px 14px'}}/>
              <TextInput source="name" fullWidth />
              <TextInput source="email" fullWidth/>
              <PasswordInput source="password" fullWidth/>
              <BooleanInput source="status"/>
              <SelectInput source="permissionLevel" fullWidth choices={[
                { id: 4, name: 'normal' },
                { id: 16, name: 'projects manager' },
                { id: 2048, name: 'super admin' },
              ]} />
            </SimpleForm>
          </Create>
        </CardContent>
      </Card>
    </ComponentEdit>
  )
}

export default UserCreate;
import UserIcon from '@mui/icons-material/AttachMoney';
import UserList from './list';
import UserEdit from './edit';

export default {
    list: UserList,
    edit: UserEdit,
    icon: UserIcon,
};


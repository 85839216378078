import * as React from 'react';
import { Fragment } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  NullableBooleanInput,
  ReferenceInput,
  SearchInput,
  SelectColumnsButton,
  TextField,
  TextInput,
  TopToolbar,
  ShowButton,
  EditButton,
  ReferenceField,
  DeleteButton
} from 'react-admin';
import { useMediaQuery, Divider, Theme } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ComponentList from '../../components/PageComponents/ComponentList';
import MobileGrid from './MobileGrid';
import { Project } from '../../models/project';


const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <ExportButton />
  </TopToolbar>

);

const UserList = () => (
  <>
    <Helmet>
      <title>Coinhouse Solutions - Users list</title>
    </Helmet>
    <ComponentList title="Project Management" subtitle="All projects can be managed from this page" filters={orderFilters} actions={<ListActions />}>
      <TabbedDatagrid />
    </ComponentList>
  </>
);

const orderFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="accountId" reference="accounts">
    <AutocompleteInput
      optionText={(choice?: Project) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice.name}`
          : ''
      }
    />
  </ReferenceInput>,
  <DateInput source="dateCreated" />,
  <TextInput source="Id" />,
  <NullableBooleanInput source="active" />,
];

const TabbedDatagrid = () => {
  const isXSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('sm')
  );

  return (
    <Fragment>
      <Divider />
      {isXSmall ? (
        <MobileGrid />
      ) : (
        <>
          <DatagridConfigurable
            rowClick="show"
            omit={[]}
            sx={{
              '& .RaDatagrid-headerCell': {
                padding: '16px',
                background: 'none',
              },
              '& .RaDatagrid-headerRow': {
                transition: 'background-color 0.2s ease 0s !important',
                backgroundColor: 'rgba(34, 51, 84, 0.02)  !important'
              }
            }}
          >
            <TextField source="name" />
            <TextField source="codeProject" />
            <ReferenceField source="accountId" reference="accounts" />
            <DateField source="created" showTime />
            <DateField source="updated" showTime />
            <BooleanField source="status" valueLabelTrue="active" valueLabelFalse="inactive" />
            <ShowButton />
            <EditButton />
            <DeleteButton />
          </DatagridConfigurable>
        </>
      )}
    </Fragment>
  );
};

export default UserList;
import * as React from 'react';
import { Fragment } from 'react';
import {
  AutocompleteInput,
  BooleanField,
  DatagridConfigurable,
  DateField,
  DateInput,
  ExportButton,
  FilterButton,
  NullableBooleanInput,
  ReferenceInput,
  SearchInput,
  SelectColumnsButton,
  TextField,
  TextInput,
  TopToolbar,
  useRecordContext,
  ShowButton
} from 'react-admin';
import { useMediaQuery, Divider, Theme} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Label from '../../../src/components/Label';
import ComponentList from '../../components/PageComponents/ComponentList';
import MobileGrid from './MobileGrid';
import { Customer } from '../../models/orderCard';
import { Helmet } from 'react-helmet-async';


const StatusLabel = () => {
  const { t }: { t: any } = useTranslation();
let order  = useRecordContext()
let map ={
  "CREATED":{
    state: "info",
    text: t("CREATED")
  },
  "AUTHORISATION": {
    state: "info",
    text: t("PAYMENT AUTHORISATION")
  },
  "CAPTURED": {
    state: "info",
    text: t("PAYMENT CAPTURED")
  },
  "CAPTURE": {
    state: "info",
    text: t("PAYMENT CAPTURED")
  },
  "TRANSACTION_PROCESS": {
    state: "info",
    text: t("TRANSACTION PROCESS")
  },
  "TX_PROCESSING": {
    state: "info",
    text: t("TRANSACTION BLOCKCHAIN IN PROGRESS")
  },
  "SUCCESS": {
    state: "success",
    text: t("SUCCESS")
  },
  "ERROR": {
    state: "error",
    text: t("ERROR")
  },
  "EXPIRED": {
    state: "error",
    text: t("EXPIRED")
  },
  "REFUND": {
    state: "success",
    text: t("REFUND")
  }
}
if(!order.eventCode) order.eventCode = "ERROR"
const { text, state }: any = map[order.eventCode];


  return (
    <Label color={state}>
                    <DoneTwoToneIcon fontSize="small" />
                    <b>{text}</b>
                  </Label>
  )
}


const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <ExportButton />
  </TopToolbar>

);

const OrderList = () => (
  <>
      <Helmet>
      <title>Coinhouse Solutions - Orders list</title>
    </Helmet>
  <ComponentList title="Orders Management" subtitle="All orders can be managed from this page" filters={orderFilters} actions={<ListActions/>}>
  <TabbedDatagrid />
  </ComponentList>
  </>
);

const orderFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="customer.email" reference="customer">
    <AutocompleteInput
      optionText={(choice?: Customer) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice.firstname} ${choice.lastname}`
          : ''
      }
    />
  </ReferenceInput>,
  <DateInput source="dateCreated" />,
  <TextInput source="OrderId" />,
  <NullableBooleanInput source="success" />,
];

const CustomerDetail = () => {
  const record = useRecordContext();
  return <span> {record?.customer?.firstname} {record?.customer?.lastname}</span>;
};


const Amount = () => {
  const record = useRecordContext();
  let amountRefined = (record?.amount) ? record?.amount / 100 : null;
  if (amountRefined == null) return (null)
  return <span>{amountRefined}</span>;
};

const TabbedDatagrid = () => {
  const isXSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('sm')
  );

  return (
    <Fragment>
      <Divider />
      {isXSmall ? (
        <MobileGrid />
      ) : (
        <>
          <DatagridConfigurable
            rowClick="show"
            omit={['internalRef', 'publicAddressDest', 'tx_hash']}
            sx={{
              '& .RaDatagrid-headerCell': {
                padding: '16px',
                background: 'none',
              },
              '& .RaDatagrid-headerRow': {
                transition: 'background-color 0.2s ease 0s !important',
                backgroundColor: 'rgba(34, 51, 84, 0.02)  !important'
              }
            }}
          >
            <BooleanField source="success" />
            <TextField source="OrderId" />
            <StatusLabel/>
            <TextField source="internalRef" />
            <TextField source="tokenId" />
            <TextField source="codeProject" />
            <DateField source="dateCreated" showTime />
            <CustomerDetail />
            <TextField source="publicAddressDest" />
            <Amount />
            <TextField source="tx_hash" />
            <ShowButton />
          </DatagridConfigurable>
        </>
      )}
    </Fragment>
  );
};

export default OrderList;
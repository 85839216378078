import { FC, ReactNode } from 'react';
import { useSidebarState } from 'react-admin';
import Header from './Header';
import Sidebar from './Sidebar';
import { Box, useTheme, alpha, lighten } from '@mui/material';
import Footer from '../components/Footer';
import { Padding } from '@mui/icons-material';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <Box
      sx={{
        flex: 1,
        height: '100%'
      }}
    >
      {children}
    </Box>
  );
};


const CoinhouseSolutionsAdminLayout = ({
  children,
  dashboard,
  title,
}) => {
  const [open] = useSidebarState();
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          flex: 1,
          height: '100%',

          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`
          }
        }}
      >
        <Header />
        <Sidebar />
        <Box
          sx={{
            position: 'relative',
            zIndex: 5,
            display: 'block',
            flex: 1,
            pt: `${theme.header.height}`,
            [theme.breakpoints.up('lg')]: {
              ml: `${theme.sidebar.width}`
            },
            backgroundColor: '#F3F3F3F3',
            paddingBottom: '2.5rem'
          }}
        >
          {children}
          <Footer/>
        </Box>
      </Box>
    </>
  );
};


CoinhouseSolutionsAdminLayout.propTypes = {
  //    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  //    dashboard: PropTypes.oneOfType([
  //        PropTypes.func,
  //        PropTypes.string,
  //    ]),
  //    title: PropTypes.string.isRequired,
};

export default CoinhouseSolutionsAdminLayout;

import { useTranslation } from 'react-i18next';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Label from '.';
import { useRecordContext } from "react-admin";



const StatusLabel = () => {
    const { t }: { t: any } = useTranslation();
  let order  = useRecordContext()
  let map ={
    "CREATED":{
      state: "info",
      text: t("CREATED")
    },
    "AUTHORISATION": {
      state: "info",
      text: t("PAYMENT AUTHORISATION")
    },
    "CAPTURED": {
      state: "info",
      text: t("PAYMENT CAPTURED")
    },
    "CAPTURE": {
      state: "info",
      text: t("PAYMENT CAPTURED")
    },
    "TRANSACTION_PROCESS": {
      state: "info",
      text: t("TRANSACTION PROCESS")
    },
    "TX_PROCESSING": {
      state: "info",
      text: t("TRANSACTION BLOCKCHAIN IN PROGRESS")
    },
    "SUCCESS": {
      state: "success",
      text: t("SUCCESS")
    },
    "ERROR": {
      state: "error",
      text: t("ERROR")
    },
    "EXPIRED": {
      state: "error",
      text: t("EXPIRED")
    },
    "REFUND": {
      state: "success",
      text: t("REFUND")
    }
  }
  if(!order?.eventCode) order.eventCode = "ERROR"
  const { text, state }: any = map[order.eventCode];
  
  
    return (
      <Label color={state}>
                      <DoneTwoToneIcon fontSize="small" />
                      <b style={{ whiteSpace: 'nowrap' }}>{text}</b>
                    </Label>
    )
  }

  export default StatusLabel
import React from 'react';

const LogoCoinhouse = (fillColor) => {
  let color = (fillColor.fillColor)? fillColor.fillColor:'white'
  return (
    <svg width="207" height="30" viewBox="0 0 519 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.4732 18.463L48.0137 8.47535C47.8928 8.40631 47.7557 8.37002 47.6161 8.37016C47.4765 8.37029 47.3394 8.40685 47.2187 8.47613C47.0979 8.54542 46.9978 8.64498 46.9284 8.76478C46.859 8.88458 46.8227 9.02038 46.8232 9.15847V20.8186C46.8227 20.9569 46.8591 21.093 46.9288 21.2129C46.9985 21.3329 47.0989 21.4325 47.2201 21.5017L54.1642 25.4277C54.2853 25.4968 54.3858 25.5965 54.4554 25.7164C54.5251 25.8364 54.5615 25.9724 54.561 26.1108L54.3626 49.0149C54.3623 49.154 54.3247 49.2906 54.2536 49.4106C54.1825 49.5306 54.0804 49.6298 53.9578 49.698L40.5458 57.1573C40.4251 57.2244 40.2888 57.2591 40.1504 57.258C40.012 57.2569 39.8762 57.22 39.7567 57.1509C39.6372 57.0819 39.538 56.9831 39.469 56.8644C39.3999 56.7456 39.3635 56.6111 39.3633 56.4742V44.1859C39.3627 44.0517 39.3274 43.9199 39.2607 43.8031C39.194 43.6863 39.0982 43.5883 38.9823 43.5185L36.4983 42.0188C36.3777 41.9499 36.2408 41.9136 36.1015 41.9136C35.9622 41.9136 35.8254 41.9499 35.7047 42.0188L27.1654 46.8399C27.044 46.9074 26.9427 47.0053 26.8716 47.1239C26.8006 47.2424 26.7623 47.3773 26.7607 47.5151V71.1416C26.7601 71.2799 26.7965 71.416 26.8662 71.536C26.9359 71.6559 27.0364 71.7555 27.1575 71.8247L32.5382 74.8948C32.6588 74.9637 32.7957 75 32.935 75C33.0743 75 33.2112 74.9637 33.3318 74.8948L65.4732 56.5213C65.5943 56.4521 65.6948 56.3525 65.7645 56.2325C65.8341 56.1126 65.8705 55.9765 65.87 55.8382V19.1383C65.8692 19.0012 65.8321 18.8668 65.7625 18.7484C65.6929 18.6299 65.5931 18.5315 65.4732 18.463Z" fill={color} />
      <path d="M27.1257 31.7092L29.4272 33.0283C29.5479 33.0973 29.6847 33.1335 29.824 33.1335C29.9633 33.1335 30.1002 33.0973 30.2208 33.0283L38.8712 28.0188C38.9897 27.9493 39.0879 27.8505 39.156 27.7321C39.2242 27.6137 39.2601 27.4798 39.2601 27.3435V3.9762C39.2607 3.83784 39.2243 3.70179 39.1546 3.58183C39.0849 3.46187 38.9844 3.36226 38.8633 3.29308L33.308 0.1052C33.1874 0.0362849 33.0505 0 32.9112 0C32.7719 0 32.635 0.0362849 32.5144 0.1052L0.396813 18.463C0.275704 18.5322 0.175223 18.6318 0.105541 18.7518C0.0358594 18.8717 -0.000548931 19.0078 6.25568e-06 19.1461V55.8382C-0.000548931 55.9765 0.0358594 56.1126 0.105541 56.2325C0.175223 56.3525 0.275704 56.4521 0.396813 56.5213L18.0785 66.6267C18.1994 66.6958 18.3366 66.7321 18.4761 66.7319C18.6157 66.7318 18.7528 66.6952 18.8735 66.626C18.9943 66.5567 19.0944 66.4571 19.1638 66.3373C19.2333 66.2175 19.2695 66.0817 19.269 65.9436V54.2756C19.2703 54.1381 19.2351 54.0025 19.1669 53.8826C19.0986 53.7627 18.9997 53.6627 18.8801 53.5925L11.9201 49.5645C11.8005 49.4943 11.7016 49.3943 11.6333 49.2744C11.5651 49.1545 11.5299 49.019 11.5312 48.8814L11.6661 26.2757C11.6683 26.14 11.7061 26.0072 11.7756 25.8902C11.8451 25.7732 11.9441 25.6761 12.0629 25.6083L25.483 17.8427C25.6033 17.774 25.7397 17.7377 25.8786 17.7375C26.0175 17.7373 26.154 17.7732 26.2745 17.8415C26.395 17.9099 26.4953 18.0083 26.5653 18.127C26.6353 18.2457 26.6726 18.3805 26.6734 18.518L26.7369 31.0339C26.7369 31.1702 26.7728 31.3041 26.8409 31.4225C26.9091 31.5409 27.0073 31.6397 27.1257 31.7092Z" fill={color} />
      <path d="M203.562 61.6486V13.265H215.919V61.6486H203.562Z" fill={color} />
      <path d="M171.587 23.8259C174.309 23.8259 176.97 24.6244 179.233 26.1205C181.496 27.6165 183.26 29.7429 184.301 32.2308C185.343 34.7186 185.615 37.4562 185.084 40.0973C184.553 42.7384 183.243 45.1644 181.318 47.0685C179.393 48.9727 176.941 50.2694 174.272 50.7947C171.603 51.3201 168.836 51.0505 166.321 50.0199C163.807 48.9894 161.657 47.2444 160.145 45.0053C158.633 42.7663 157.826 40.1339 157.826 37.4411C157.828 33.8308 159.279 30.3689 161.859 27.816C164.439 25.2631 167.938 23.8279 171.587 23.8259ZM171.587 12.3149C166.565 12.3149 161.655 13.7886 157.478 16.5495C153.302 19.3104 150.047 23.2345 148.125 27.8257C146.203 32.4169 145.7 37.469 146.68 42.343C147.66 47.217 150.078 51.694 153.63 55.208C157.182 58.722 161.707 61.115 166.633 62.0845C171.559 63.054 176.665 62.5564 181.306 60.6547C185.946 58.7529 189.913 55.5324 192.703 51.4005C195.494 47.2685 196.983 42.4106 196.983 37.4411C196.983 30.7772 194.307 24.3863 189.545 19.6742C184.782 14.9622 178.323 12.3149 171.587 12.3149Z" fill={color} />
      <path d="M259.861 13.265V49.4467H258.885L244.203 13.265H224.521V61.6722H236.116V25.4905H236.91L251.766 61.6722L251.814 61.5779L251.774 61.6722H271.456V13.265H259.861Z" fill={color} />
      <path d="M116.463 51.1035C113.963 51.1142 111.508 50.4513 109.361 49.1858C107.213 47.9203 105.455 46.1 104.274 43.9204C103.093 41.7408 102.535 39.2842 102.659 36.8142C102.782 34.3443 103.584 31.9544 104.977 29.901C106.369 27.8476 108.301 26.2083 110.565 25.1592C112.828 24.11 115.337 23.6906 117.824 23.9459C120.31 24.2012 122.679 25.1216 124.676 26.6083C126.673 28.095 128.224 30.0918 129.161 32.3845H141.295C140.034 26.2501 136.511 20.7995 131.412 17.0938C126.313 13.388 120.002 11.6923 113.709 12.3367C107.415 12.9812 101.589 15.9196 97.3651 20.5801C93.141 25.2406 90.8209 31.2895 90.8565 37.5493C90.8921 43.8091 93.2808 49.8319 97.5577 54.445C101.835 59.0581 107.693 61.9316 113.994 62.5059C120.294 63.0802 126.585 61.3143 131.642 57.552C136.699 53.7897 140.16 48.3002 141.351 42.1523H129.335C128.374 44.7684 126.624 47.0296 124.322 48.6303C122.02 50.2311 119.277 51.0943 116.463 51.1035Z" fill={color} />
      <path d="M323.898 61.5779H311.502V42.5056H292.352V61.5779H280.019V13.265H292.352V32.0154H311.502V13.265H323.898V61.5779Z" fill={color} />
      <path d="M355.984 12.3778C341.532 12.3778 329.834 23.4411 329.834 37.5039C329.834 51.5667 341.532 62.5437 355.984 62.5437C370.435 62.5437 382.07 51.6138 382.07 37.5039C382.07 23.394 370.372 12.3778 355.984 12.3778ZM355.984 51.5981C348.341 51.5981 342.302 45.4972 342.302 37.4647C342.302 29.4321 348.341 23.3312 355.984 23.3312C363.626 23.3312 369.666 29.5028 369.666 37.4647C369.666 45.4265 363.626 51.5981 355.984 51.5981Z" fill={color} />
      <path d="M431.845 39.8988C431.845 53.8202 423.044 62.5908 409.942 62.5908C396.45 62.5908 387.458 53.8202 387.458 39.8988V13.265H399.918V39.4591C399.918 46.7614 403.886 51.0878 409.942 51.0878C415.592 51.0878 419.465 46.7614 419.465 39.4591V13.265H431.861L431.845 39.8988Z" fill={color} />
      <path d="M476.272 26.2364L464.201 28.6705C463.169 23.7788 459.828 22.389 456.939 22.389C454.051 22.389 451.67 23.8494 451.67 26.5191C451.67 28.4271 452.694 29.6598 454.844 30.2723L464.368 33.256C472.399 35.8629 476.764 39.5376 476.764 47.1147C476.764 57.9818 467.129 62.5594 457.558 62.5594C446.956 62.5594 438.345 57.0631 437.123 47.8135L449.821 45.2695C450.852 50.0356 453.741 52.0693 457.979 52.0693C461.582 52.0693 463.764 50.4204 463.764 47.8763C463.764 45.9683 462.733 44.6335 460.034 43.9504L450.979 41.3435C443.591 39.2471 438.575 35.5017 438.575 27.6812C438.575 17.827 446.416 12.4249 457.082 12.4249C467.153 12.3778 474.669 17.2067 476.272 26.2364Z" fill={color} />
      <path d="M519 61.5779H482.573V13.265H518.611V23.7552H494.906V32.5886H516.945V42.38H494.906V51.0878H519V61.5779Z" fill={color} />
    </svg>

  )
}

export default LogoCoinhouse;
import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
  TableContainer,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment'


const CellDate = (__date: any) => {
  let date = moment(__date.date).format('DD/MM/YYYY H:mm:ss')
  return (
    <span>{date}</span>
  )
}
const TablePayments = (payments: any) => {
  const { t }: { t: any } = useTranslation();
  if(payments.payments.length == 0) return (<></>)
  let paymentsArray = payments.payments

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          subheaderTypographyProps={{}}
          titleTypographyProps={{}}
          title={t('Payments list')}
          subheader={t('All payments registered for this order')}
        />
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {/*<TableCell>{t('PaymentId')}</TableCell>*/}
                <TableCell>{t('Date')}</TableCell>
                <TableCell>{t('amount')}</TableCell>
                <TableCell>{t('currency')}</TableCell>
                <TableCell>{t('type')}</TableCell>
                <TableCell>{t('credit')}</TableCell>
                <TableCell>{t('debit')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentsArray.map((payment) => (
               
                <TableRow key={payment.PaymentId} hover>
                  {/*<TableCell>{payment.PaymentId}</TableCell>*/}
                  <TableCell><CellDate date={payment.dateTime}/></TableCell>
                  <TableCell><Amount amount={payment.amount}/></TableCell>
                  <TableCell>{payment.currency}</TableCell>
                  <TableCell>{payment.type}</TableCell>
                  <TableCell><Amount amount={payment.credit}/></TableCell>
                  <TableCell><Amount amount={payment.debit}/></TableCell>
                  
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Grid>
  )
}


const Amount = (amount: any) => {
  let _amount = (amount.amount / 100).toString() + ' €'
  return (
    <>
      <Typography sx={{
        fontSize: 15
      }}>
        {_amount}
      </Typography>
    </>
  )
}

function InfoPaymentTab(payments) {
  const { t }: { t: any } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box pb={2}>
          <Typography variant="h3">{t('Payment informations')}</Typography>
          <Typography variant="subtitle2">
            {t('All information about this order')}
          </Typography>
        </Box>
        <TablePayments payments={payments.payments}/>

      </Grid>

    </Grid>
  );
}

export default InfoPaymentTab;

import * as React from 'react';
import { Fragment } from 'react';
import {
  AutocompleteInput,
  DatagridConfigurable,
  DateInput,
  ExportButton,
  FilterButton,
  NullableBooleanInput,
  ReferenceInput,
  SearchInput,
  SelectColumnsButton,
  TextField,
  TextInput,
  TopToolbar,
  EmailField,
  ShowButton,
  EditButton,
  ReferenceField,
  DeleteWithConfirmButton,
  CreateButton,
  useRecordContext,
  Toolbar
} from 'react-admin';
import { useMediaQuery, Divider, Theme, styled } from '@mui/material';
import ComponentList from '../../components/PageComponents/ComponentList';
import MobileGrid from './MobileGrid';
import { User } from '../../models/user';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Label from '../../../src/components/Label';
import { useTranslation } from 'react-i18next';




const CardEmail = styled(EmailField)(
  ({ theme }) => `
      color: ${theme.colors.primary};
      padding-left: 10px;
      font-weight: 500
`
);

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <ExportButton />
    <CreateButton />
  </TopToolbar>

);

const UserList = () => (
  <ComponentList title="User Management" subtitle="All users can be managed from this page" filters={orderFilters} actions={<ListActions />}>
    <TabbedDatagrid />
  </ComponentList>
);

const orderFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput source="user.account" reference="account">
    <AutocompleteInput
      optionText={(choice?: User) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice.firstname} ${choice.lastname}`
          : ''
      }
    />
  </ReferenceInput>,
  <DateInput source="dateCreated" />,
  <TextInput source="Id" />,
  <NullableBooleanInput source="active" />,
];

const CustomDeleteWithConfirmButton = () => {
  const record = useRecordContext();
  const { t }: { t: any } = useTranslation();

  return (
<DeleteWithConfirmButton confirmTitle={t(`Delete user: ${record.name} ?`)} translateOptions={{ id:"", name: record.name }}/>
  )
}
const TabbedDatagrid = () => {
  const { t }: { t: any } = useTranslation();
  const isXSmall = useMediaQuery<Theme>(theme =>
    theme.breakpoints.down('sm')
  );

  return (
    <Fragment>
      <Divider />
      {isXSmall ? (
        <MobileGrid />
      ) : (
        <>
          <DatagridConfigurable

            rowClick="show"
            omit={[]}
            sx={{
              '& .RaDatagrid-headerCell': {
                padding: '16px',
                background: 'none',
              },
              '& .RaDatagrid-headerRow': {
                transition: 'background-color 0.2s ease 0s !important',
                backgroundColor: 'rgba(34, 51, 84, 0.02)  !important'
              }
            }}
          >
            <TextField source="id" />
            <TextField source="name" sx={{ fontWeight: 600 }} />
            <Label color="primary">
              <MailOutlineIcon fontSize="small" />
              <CardEmail source="email" />
            </Label>

            <ReferenceField source="accountId" reference="accounts" />
            <ShowButton />
            <EditButton />
            <CustomDeleteWithConfirmButton/>
          </DatagridConfigurable>
        </>
      )}
    </Fragment>
  );
};

export default UserList;
import UserIcon from '@mui/icons-material/AttachMoney';
import UserList from './list';
import UserEdit from './edit';
import UserShow from './single'
import UserCreate from './create'
export default {
    list: UserList,
    edit: UserEdit,
    create: UserCreate,
    icon: UserIcon,
    show: UserShow
};


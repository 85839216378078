import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import BASE_API_URL from "../configUrl";

const axiosInt = axios.create({
  baseURL: BASE_API_URL
});

axiosInt.interceptors.request.use(function (config) {
  return config;
}, function (error) {
  return Promise.reject(error);
});

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

export const mock = new AxiosMockAdapter(axiosInt, { delayResponse: 0 });

export default axiosInt;

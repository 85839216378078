import {
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Text from '../../../../src/components/Text';
import Label from '../../../../src/components/Label';
import { Show, SimpleShowLayout, TextField, EmailField, ReferenceField, useRecordContext, DateField } from "react-admin";
import { Badge } from '@mui/icons-material';
import StatusLabel from '../../../components/Label/status';

const Amount = () => {
  let order  = useRecordContext()
  let _amount = (order.amount / 100).toString() + ' €'

  return (
    <>
    <Typography sx={{
      fontWeight: 800,
      fontSize: 18
    }}>
      {_amount}
    </Typography>
    </>
  )
}

function InfoOrderTab() {
  const { t }: { t: any } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
                <Box pb={2}>
          <Typography variant="h3">{t('Order informations')}</Typography>
          <Typography variant="subtitle2">
            {t('All information about this order')}
          </Typography>
        </Box>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('Summary')}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <CardContent
            sx={{
              p: 4
            }}
          >
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Id')}:
                  </Box>
                </Grid>
                 <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <TextField source="OrderId" />
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Amount')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <Amount />
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Token Id')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <TextField source="tokenId" sx={{
                      fontWeight: 800,
                      fontSize: 18
                    }}/>
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Created date')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <DateField showTime={true} source="dateCreated" sx={{
                      fontWeight: 800,
                      fontSize: 18
                    }}/>
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Order status')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <StatusLabel/>
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <Box
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h4" gutterBottom>
                {t('Order details')}
              </Typography>

            </Box>
            {/*<Button variant="text" startIcon={<EditTwoToneIcon />}>
              {t('Edit')}
                  </Button>*/}
          </Box>
          <Divider />
          <CardContent
            sx={{
              p: 4
            }}
          >
            <Typography variant="subtitle2">
              <Grid container spacing={0}>
              <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Id')}:
                  </Box>
                </Grid>
                 <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <TextField source="OrderId" />
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Amount')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <Amount />
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Token Id')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <TextField source="tokenId" sx={{
                      fontWeight: 800,
                      fontSize: 18
                    }}/>
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Created date')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <DateField showTime={true} source="dateCreated" sx={{
                      fontWeight: 800,
                      fontSize: 18
                    }}/>
                  </Text>
                </Grid>
                <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {t('Order status')}:
                  </Box>
                </Grid>
                <Grid item xs={12} sm={8} md={9}>
                  <Text color="black">
                    <StatusLabel/>
                  </Text>
                </Grid>
              </Grid>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default InfoOrderTab;

import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Avatar,
  useTheme,
  styled
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ShoppingBagTwoToneIcon from '@mui/icons-material/ShoppingBagTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import { useRecordContext,DateField } from 'react-admin';
import Label from '../../../../src/components/Label';
import StatusLabel from '../../../components/Label/status';


const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
      background: ${theme.colors.primary.lighter};
      color: ${theme.colors.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const Amount = () => {
  let order  = useRecordContext()
  let _amount = (order.amount / 100).toString() + ' €'

  return (
    <>
    <Typography sx={{
      fontWeight: 800,
      fontSize: 18
    }}>
      {_amount}
    </Typography>
    </>
  )
}

function RecentActivity() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  return (
    <Card>
      <CardHeader title={t('Quick view')} />
      <Divider />
      <Box px={2} py={4} display="flex" alignItems="flex-start">
        <AvatarPrimary >
          <ShoppingBagTwoToneIcon fontSize='small'/>
        </AvatarPrimary>
        <Box pl={2} flex={1}>
          <Typography variant="h3">{t('Infos')}</Typography>

          <Box pt={2} display="flex">
            <Box pr={6}>
              <Typography
                gutterBottom
                variant="caption"
                sx={{
                  fontSize: `${theme.typography.pxToRem(16)}`
                }}
              >
                {t('Amount')}
              </Typography>
            </Box>
            <Box>
              <Amount/>
            </Box>
          </Box>
          <Box pt={2} display="flex">
            <Box pr={6}>
              <Typography
                gutterBottom
                variant="caption"
                sx={{
                  fontSize: `${theme.typography.pxToRem(16)}`
                }}
              >
                {t('Status')}
              </Typography>
            </Box>
            <Box>
              <StatusLabel/>
            </Box>
          </Box>
          <Box pt={2} display="flex">
            <Box pr={6}>
              <Typography
                gutterBottom
                variant="caption"
                sx={{
                  fontSize: `${theme.typography.pxToRem(16)}`
                }}
              >
                {t('Created date')}
              </Typography>
            </Box>
            <Box>
            <DateField showTime={true} source="dateCreated" sx={{
                      fontWeight: 800,
                      fontSize: 18
                    }}/>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider />
    </Card>
  );
}

export default RecentActivity;

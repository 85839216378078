import ProjectIcon from '@mui/icons-material/AttachMoney';
import ProjectList from './list';
import ProjectEdit from './edit';
import ProjectShow from './show';


export default {
    list: ProjectList,
    edit: ProjectEdit,
    icon: ProjectIcon,
    show: ProjectShow
};

